<template>
  <div class="Regulamento">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <Topo titulo="Condições gerais" @OpenMenuLateral="menuOpen = true"></Topo>
      <div class="showPdf">
        <b-pagination
          v-model="currentPage"
          :total-rows="pageCount"
          :per-page="1"
          aria-controls="pdf"
          class="ml-3 mb-3"
        ></b-pagination>
        <b-row>
          <b-col>
            <div v-if="regulamento" ref="mypdf" class="regulamento">
              <pdf
                :src="regulamento"
                @num-pages="pageCount = $event"
                @page-loaded="currentPage = $event"
                :page="currentPage"
                @loaded="onLoad"
              ></pdf>
            </div>
          </b-col>
        </b-row>
      </div>
      <MenuRodape
        @OpenMenuLateral="menuOpen = true"
        :decoded="decoded"
      ></MenuRodape>
    </div>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import Topo from './TopoInterno';
import MenuRodape from './MenuRodape';
import ConteudoDash from './ConteudoDash';
import MenuLateral from './MenuLateral';
import service from '../services';
import pdf from 'vue-pdf';
import panzoom from 'panzoom';
import { decryptData } from '../services/decryptData';

export default {
  name: 'DashBoard',
  props: {
    msg: String,
  },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      carregando: false,
      regulamento: null,
      currentPage: 1,
      pageCount: 0,
    };
  },
  components: { Topo, ConteudoDash, MenuRodape, MenuLateral, pdf },
  methods: {
    onLoad() {
      panzoom(this.$refs.mypdf);
    },
    verificaToken() {
      const token = localStorage.getItem('user-token');
      if (token) {
        this.decoded = jwt.decode(token);
        console.log(this.decoded);

        service
          .getAll('g', {}, 'empresas/list/idEmpresa/' + this.decoded.id_empresa)
          .then((res) => {
            res.data = decryptData(res.data);
            this.regulamento = res.data[0].regulamento;
          })
          .catch((e) => {
            this.carregando = false;
            this.erroConexao =
              'Seu aplicativo parece estar offline, verifique sua conexao';
          });
      }
    },
  },
  mounted() {
    this.verificaToken();
  },
};
</script>
<style lang="scss" scoped>
.showPdf {
  padding-bottom: 100px;
  padding-top: 20px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body {
  overflow-x: hidden;
}

.iconeFechar {
  font-size: 28px;
}
.bm-menu {
  background: #373a47;
}
.bm-menu {
  background-color: #3f3f41;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transition: 0.2s;
  width: 0;
  z-index: 9999;
  color: #fff;
}
.bm-item-list > * {
  display: flex;
  padding: 0.7em;
  text-decoration: none;
}
.bm-item-list > * > span {
  color: #fff;
  font-weight: 700;
  margin-left: 10px;
}
.bm-item-list > * > i {
  color: #666;
  font-weight: 700;
  margin-left: 10px;
}
.logoBottom {
  margin-top: 100px;
  text-align: center;
  opacity: 0.5;
}
</style>
